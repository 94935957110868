import { SET_MESSAGE, SET_MODAL } from '../types/types';

export const initialState = {
  modal: false,
  message: '',
};

export const appReducer = (state = initialState, action) => {
  const { payload, type } = action;

  switch (type) {
    case SET_MESSAGE:
      return {
        ...state,
        message: payload,
      };
    case SET_MODAL:
      return {
        ...state,
        modal: payload,
        message: '',
      };
    default:
      return state;
  }
};