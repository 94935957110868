import * as React from "react";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

export default function Modal({ projectModal, setProjectModal }) {
	return (
		<Lightbox
			open={projectModal.open}
			close={() => setProjectModal({ open: false, project: null })}
			slides={
				projectModal.name === "Extended Vehicle Care"
					? [
							{ src: "/avp1.png" },
							{ src: "/avp2.png" },
							{ src: "/avp3.png" },
							{ src: "/avp4.png" },
							{ src: "/avp5.png" },
							{ src: "/avp6.png" },
							{ src: "/avp7.png" },
							{ src: "/avp8.png" },
							{ src: "/avp9.png" },
							{ src: "/avp10.png" },
							{ src: "/avp11.png" },
							{ src: "/avp12.png" },
					  ]
					: [
							{ src: "/ads1.png" },
							{ src: "/ads2.png" },
							{ src: "/ads3.png" },
							{ src: "/ads4.png" },
							{ src: "/ads5.png" },
					  ]
			}
		/>
	);
}
