import React from "react";
import { faBriefcase } from "@fortawesome/free-solid-svg-icons";

import Card from "../common/card";

import "./styles/works.css";

const Works = () => {
	return (
		<div className="works">
			<Card
				icon={faBriefcase}
				title="Work"
				body={
					<div className="works-body">
						<div className="work">
							<img
								src="./allstate.png"
								alt="allstate"
								className="work-image"
							/>
							<div className="work-title">Allstate Insurance</div>
							<div className="work-subtitle">
								Front-End Developer
							</div>
							<div className="work-duration">2020 - 2023</div>
						</div>

						<div className="work">
							<img
								src="./allstate.png"
								alt="allstate"
								className="work-image"
							/>
							<div className="work-title">Allstate Insurance</div>
							<div className="work-subtitle">Web Developer</div>
							<div className="work-duration">2017 - 2020</div>
						</div>

						<div className="work">
							<img
								src="./allstate.png"
								alt="allstate"
								className="work-image"
							/>
							<div className="work-title">Allstate Insurance</div>
							<div className="work-subtitle">UX/UI Designer</div>
							<div className="work-duration">2014 - 2017</div>
						</div>

						<div className="work">
							<img
								src="./allstate.png"
								alt="allstate"
								className="work-image"
							/>
							<div className="work-title">Allstate Insurance</div>
							<div className="work-subtitle">Web Designer</div>
							<div className="work-duration">2010 - 2014</div>
						</div>

						<div className="work">
							<img
								src="./madden.jpeg"
								alt="madden media"
								className="work-image"
							/>
							<div className="work-title">Madden Media</div>
							<div className="work-subtitle">Web Designer</div>
							<div className="work-duration">2006 - 2010</div>
						</div>

						<div className="work">
							<img
								src="./lsi.jpeg"
								alt="madden media"
								className="work-image"
							/>
							<div className="work-title">Logistic Services Intl.</div>
							<div className="work-subtitle">Graphic Specialist</div>
							<div className="work-duration">2004 - 2006</div>
						</div>
					</div>
				}
			/>
		</div>
	);
};

export default Works;
