import React, { useReducer, useContext } from 'react';
import { appReducer, initialState } from '../reducers/AppReducer';

export const AppContext = React.createContext();

export const useApp = () => {
    return useContext(AppContext);
}

export const AppProvider = ({ children }) => {
  const [state, dispatch] = useReducer(appReducer, initialState);

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      {children}
    </AppContext.Provider>
  );
};
