import { useState, useEffect, useRef } from "react";
import { setModalMsg } from "../../lib/actions/modal-actions";
import { useApp } from "../../lib/contexts/appContext";
import "./styles/form.css";

const Form = () => {
	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [message, setMessage] = useState("");
	const { dispatch } = useApp();
	const nameInput = useRef(null);

	const resetState = () => {
		setName("");
		setEmail("");
		setMessage("");
	};

	useEffect(() => {
		resetState();
		if (nameInput.current) {
			nameInput.current.focus();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleNameChange = (event) => {
		setName(event.target.value);
	};

	const handleEmailChange = (event) => {
		setEmail(event.target.value);
	};

	const handleMessageChange = (event) => {
		setMessage(event.target.value);
	};

	const encode = (data) => {
		return Object.keys(data)
			.map(
				(key) =>
					encodeURIComponent(key) +
					"=" +
					encodeURIComponent(data[key])
			)
			.join("&");
	};

	const handleSubmit = (event) => {
		console.log(event);
		event.preventDefault();
		fetch("/", {
			method: "POST",
			headers: { "Content-Type": "application/x-www-form-urlencoded" },
			body: encode({
				"form-name": event.target.getAttribute("name"),
				name,
				email,
				message,
			}),
		})
			.then(() => {
				dispatch(setModalMsg("Message sent!"));
				resetState();
			})
			.catch(() => {
				dispatch(
					setModalMsg("Error, please try to send your message again.")
				);
			});
	};

	return (
		<>
			<div className="container">
				<form name="contact" onSubmit={handleSubmit}>
					<input type="hidden" name="form-name" value="contact" />
					<div className="form-group">
						<input
							ref={nameInput}
							type="text"
							id="name"
							name="name"
							required="required"
							onChange={handleNameChange}
							value={name}
						/>
						<label htmlFor="input" className="control-label">
							full name
						</label>
						<i className="bar"></i>
					</div>
					<div className="form-group">
						<input
							type="text"
							id="email"
							name="email"
							required="required"
							onChange={handleEmailChange}
							value={email}
						/>
						<label htmlFor="input" className="control-label">
							email address
						</label>
						<i className="bar"></i>
					</div>
					<div className="form-group">
						<textarea
							required="required"
							id="message"
							name="message"
							rows="3"
							onChange={handleMessageChange}
							value={message}
						></textarea>
						<label htmlFor="textarea" className="control-label">
							message
						</label>
						<i className="bar"></i>
					</div>
					<div className="button-container">
						<button type="submit" className="button">
							<span>send</span>
						</button>
					</div>
				</form>
			</div>
		</>
	);
};

export default Form;
