import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink } from "@fortawesome/free-solid-svg-icons";

import "./styles/project.css";

const Project = (props) => {
	const { title, description, linkText, setProjectModal } = props;

	return (
		<React.Fragment>
			<div className="project">
				<div
					onClick={() => setProjectModal({ open: true, name: title })}
					style={{ cursor: "pointer" }}
				>
					<div className="project-container">
						<div className="project-title">{title}</div>
						<div className="project-description">{description}</div>
						<div className="project-link">
							<div className="project-link-icon">
								<FontAwesomeIcon icon={faLink} />
							</div>
							<div className="project-link-text">{linkText}</div>
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default Project;
