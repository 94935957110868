import { SET_MESSAGE, SET_MODAL } from '../types/types';

export const showModal = (payload) => ({
  type: SET_MODAL,
  payload,
});

export const setModalMsg = (payload) => ({
  type: SET_MESSAGE,
  payload,
});
