const SEO = [
	{
		page: "home",
		description:
			"I'm a front-end developer dedicated to crafting immersive digital experiences. With a blend of creativity and technical expertise, I thrive on bringing ideas to life through code. I specialize in HTML, CSS, JavaScript, and React, leveraging these tools to build intuitive interfaces that captivate and engage users.",
		keywords: ["Ryan Carr", "Ryan Edward Carr", "Ryan Carr Developer"],
	},

	{
		page: "about",
		description:
			"I'm a front-end developer dedicated to crafting immersive digital experiences. With a blend of creativity and technical expertise, I thrive on bringing ideas to life through code. I specialize in HTML, CSS, JavaScript, and React, leveraging these tools to build intuitive interfaces that captivate and engage users.",
		keywords: ["Ryan Carr", "Ryan Edward Carr", "Ryan Carr Developer"],
	},

	{
		page: "articles",
		description:
			"I'm a front-end developer dedicated to crafting immersive digital experiences. With a blend of creativity and technical expertise, I thrive on bringing ideas to life through code. I specialize in HTML, CSS, JavaScript, and React, leveraging these tools to build intuitive interfaces that captivate and engage users.",
		keywords: ["Ryan Carr", "Ryan Edward Carr", "Ryan Carr Developer"],
	},

	{
		page: "projects",
		description:
			"I'm a front-end developer dedicated to crafting immersive digital experiences. With a blend of creativity and technical expertise, I thrive on bringing ideas to life through code. I specialize in HTML, CSS, JavaScript, and React, leveraging these tools to build intuitive interfaces that captivate and engage users.",
		keywords: ["Ryan Carr", "Ryan Edward Carr", "Ryan Carr Developer"],
	},

	{
		page: "contact",
		description:
			"I'm a front-end developer dedicated to crafting immersive digital experiences. With a blend of creativity and technical expertise, I thrive on bringing ideas to life through code. I specialize in HTML, CSS, JavaScript, and React, leveraging these tools to build intuitive interfaces that captivate and engage users.",
		keywords: ["Ryan Carr", "Ryan Edward Carr", "Ryan Carr Developer"],
	},
];

export default SEO;
