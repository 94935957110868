const INFO = {
	main: {
		title: "Ryan Carr - Front-End Developer",
		name: "Ryan Carr",
		email: "ryan@ryancarr.dev",
		logo: "../logo.png",
	},

	socials: {
		github: "https://github.com/ryanecarr/",
		linkedin: "https://linkedin.com/in/ryanecarr/",
	},

	homepage: {
		title: "Ryan Carr",
		subtitle: "Front-End Developer",
		description:
			"I'm a passionate front-end developer dedicated to crafting immersive digital experiences. With a blend of creativity and technical expertise, I thrive on bringing ideas to life through code. I specialize in HTML, CSS, JavaScript, and React, leveraging these tools to build intuitive interfaces that captivate and engage users.",
	},

	about: {
		title: "I’m Tharindu N. I live in Sri Lanka, where I design the future.",
		description:
			"I've worked on a variety of projects over the years and I'm proud of the progress I've made. Many of these projects are open-source and available for others to explore and contribute to. If you're interested in any of the projects I've worked on, please feel free to check out the code and suggest any improvements or enhancements you might have in mind. Collaborating with others is a great way to learn and grow, and I'm always open to new ideas and feedback.",
	},

	articles: {
		title: "I'm passionate about pushing the boundaries of what's possible and inspiring the next generation of innovators.",
		description:
			"Chronological collection of my long-form thoughts on programming, leadership, product design, and more.",
	},

	projects: [
		{
			title: "Extended Vehicle Care",
			description:
				"Extended Vehicle Care is a cutting-edge web platform designed to offer consumers a seamless experience in purchasing after market warranty products directly online. Built with React on the frontend and powered by a robust Java Spring Boot backend API, Extended Vehicle Care provides a user-friendly interface for browsing, selecting, and purchasing various warranty plans tailored to individual needs.",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/javascript/javascript.png",
			linkText: "View Project",
			link: "https://avp.allstate.com/",
		},

		{
			title: "Allstate Dealer Services",
			description:
				"Allstate Dealer Services marketing website, meticulously crafted with .NET MVC, Bootstrap, and JavaScript, is a gateway to exploring a range of aftermarket warranty products designed to safeguard vehicles.",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/python/python.png",
			linkText: "View Project",
			link: "https://www.allstatedealerservices.com/",
		},
	],
};

export default INFO;
